import React from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import ScrollToTop from "./components/scrollToTop.js";
import injectContext from "./store/appContext.js";
// Import Components & Views
import {NavbarMain} from "./components/NavbarMain.jsx";
import {NavbarSocial} from "./components/NavbarSocial.jsx";
import {NavbarLegal} from "./components/NavbarLegal.jsx";
import {Home} from "./views/Home.jsx";
import {PrivacyPolicy} from "./views/PrivacyPolicy.jsx";
// import {Gists} from "./views/Gists.jsx";
import {Publications} from "./views/Pubications.jsx";
import {Request} from "./views/Request.jsx";
import {NoMatch} from "./views/NoMatch.jsx";

//create your first component
const Layout = () => {
  // the basename is used when your project is published in a subdirectory and not in the root of the domain
  // you can set the basename on the .env file located at the root of this project, E.g: BASENAME=/react-hello-webapp/
  // const basename = process.env.BASENAME || "";


  return (
    <div>
      <BrowserRouter>
        {/*<BrowserRouter basename={basename}>*/}
        <ScrollToTop>
          <NavbarMain/>
          <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
            <Route path={'/publications'} element={<Publications/>}/>
            {/*<Route path={'/gists'} element={<Gists/>}/>*/}
            <Route path={'/request'} element={<Request/>}/>
            <Route path="*" element={<NoMatch/>}/>
          </Routes>
          <NavbarSocial/>
          <NavbarLegal/>
        </ScrollToTop>
      </BrowserRouter>
    </div>
  );
};

export default injectContext(Layout);