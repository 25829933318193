import React, {useContext} from "react";
import {Context} from "../store/appContext.js";
import {Link} from "react-router-dom";
// React-Bootstrap
import {Button, Container, Nav, Navbar, NavDropdown} from 'react-bootstrap';
// Components & Logo
import {NavbarUtil} from "./NavbarUtil";
import {CallToActionPrimary} from "./CallToActionPrimary";
import logo from "../assets/images/hcht-logo-navbar.png";

export const NavbarMain = () => {
  const {store, actions} = useContext(Context);
  let linkClass = 'link-warning text-decoration-none';
  let subLinkClass = 'text-secondary text-decoration-none';
  let i = store.isSpanish ? 0 : 1;
  let text = [
    {
      'flag' : '🇪🇸 ESP',
      'logoAlt': 'Héctor Chocobar Torrejón',
      'siteName': 'Héctor Chocobar Torrejón',
      'siteDescription': 'Ingeniero en Sistemas de Información',
      'menu1': 'Acerca de mí',
      'pathMenu1': '/',
      'menu2': 'Publicaciones',
      'pathMenu2': '/publications',
      'menu3': 'Enlaces',
      'pathMenu3': '/',
    },
    {
      'flag' : '🇺🇲 ENG',
      'logoAlt': 'Hector Chocobar-Torrejon',
      'siteName': 'Hector Chocobar-Torrejon',
      'siteDescription': 'BE, CS – Bachelor of Engineering in Computer Science',
      'menu1': 'About Me',
      'pathMenu1': '/',
      'menu2': 'Publications',
      'pathMenu2': '/publications',
      'menu3': 'Links',
      'pathMenu3': '/',
    }
  ];

  return (
    <Navbar bg="dark" variant="dark" expand="lg" fixed="top">
      <Container fluid>
        <Navbar.Brand>
          <Link to={"/"}>
            <img
              alt={text[i].logoAlt}
              src={logo}
              width="40"
              height="40"
              className="d-inline-block align-top"
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Text className={'d-none d-sm-inline'}>
          <strong>{text[i].siteName}</strong><br/>
          <small>{text[i].siteDescription}</small>
        </Navbar.Text>
        <Navbar.Text className={'d-inline d-sm-none'}>
          <CallToActionPrimary/>
        </Navbar.Text>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse id="basic-navbar-nav" className={'justify-content-end'}>
          <Nav>
            <Nav.Link><Link className={linkClass} to={text[i].pathMenu1}>{text[i].menu1}</Link></Nav.Link>
            <Nav.Link><Link className={linkClass} to={text[i].pathMenu2}>{text[i].menu2}</Link></Nav.Link>
            <NavbarUtil/>
            <Nav.Item className={'d-none d-sm-inline'}>
              <CallToActionPrimary />
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

            {/*<NavDropdown title={text[i].menu2} id="basic-nav-dropdown">*/}
            {/*  <NavDropdown.Item>*/}
            {/*    <Link className={subLinkClass} to={"/"}>*/}
            {/*      {text[i].menu21}*/}
            {/*    </Link>*/}
            {/*  </NavDropdown.Item>*/}
            {/*  <NavDropdown.Item>*/}
            {/*    <Link className={subLinkClass} to={"/"}>*/}
            {/*      {text[i].menu22}*/}
            {/*    </Link>*/}
            {/*  </NavDropdown.Item>*/}
            {/*  <NavDropdown.Item>*/}
            {/*    <Link className={subLinkClass} to={"/"}>*/}
            {/*      {text[i].menu23}*/}
            {/*    </Link>*/}
            {/*  </NavDropdown.Item>*/}
            {/*</NavDropdown>*/}
