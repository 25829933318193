import React, { useContext } from "react";
import { Context } from "../store/appContext.js";
import { Nav, NavDropdown } from 'react-bootstrap';


export const NavbarUtil = () => {
  const { store, actions } = useContext(Context);
  let i = store.isSpanish ? 0 : 1;
  let text = [
    {
      'flag' : '🇪🇸 ESP',
      'english' : '🇺🇸 Inglés',
      'spanish' : '🇪🇸 Español'
    },
    {
      'flag' : '🇺🇲 ENG',
      'english' : '🇺🇸 English',
      'spanish' : '🇪🇸 Spanish'
    }
  ];


  return (
    <Nav>
      <NavDropdown title={text[i].flag} id="basic-nav-dropdown">
        <NavDropdown.Item onClick={actions.anyLanguage}>{text[i].english}</NavDropdown.Item>
        <NavDropdown.Item onClick={actions.spanishLanguage}>{text[i].spanish}</NavDropdown.Item>
      </NavDropdown>
    </Nav>
  );
}