const getState = ({ getStore, getActions, setStore }) => {
	return {
		store: {
      style : {
        bgColorElement : '0c579b',
      },
			isSpanish: false,  // Support two language: Spanish (true) and English (false)
		},
		actions: {
			// Set Language
			spanishLanguage: () => { setStore({ isSpanish: true, }); },  // set language in Spanish
			anyLanguage: () => { setStore({ isSpanish: false, }); },  // set language in English
			// Use getActions to call a function within a function
			// exampleFunction: () => {
			// 	getActions().changeColor(0, "green");
			// },
			// changeColor: (index, color) => {
			// 	const store = getStore();  //get the store
			// 	// we have to loop the entire demo array to look for the respective index and change its color
			// 	const demo = store.demo.map((elm, i) => {
			// 		if (i === index) elm.background = color;
			// 		return elm;
			// 	});
			// 	setStore({ demo: demo });  //reset the global store
			// }
			// getPlanets: async () => {
      //     const store = getStore();
      //     if (localStorage.getItem("planets") === null) {
      //       const url = `https://swapi.dev/api/planets`;
      //       const requestOption = {
      //         method: "GET",
      //         ContentType: "application/json",
      //       };
      //       const response = await fetch(url, requestOption);
      //       if (response.ok) {
      //         const data = await response.json();
      //         setStore({ planets: data.results, });
      //         localStorage.setItem(`planets`, JSON.stringify(store.planets));
      //         let storage = localStorage.getItem("planets");
      //       } else {
      //         console.log("error: ", response.status, response.statusText);
      //       }
      //     } else {
      //       setStore({ planets: JSON.parse(localStorage.getItem("planets")), });
      //     };
      // },
      // getPlanet: (planet) => { setStore({ selectPlanet: planet, }); },
      // addFavorite: ({id, type, name}, favorites) => {
      //     const exist = favorites.find(item => item.name == name);
      //     // console.log("existe?:", exist);
      //     if (exist == undefined) {
      //       setStore({ favorites: [...favorites, {id, type, name}]});
      //     }
      // },
      // removeFavorite: (removeItem, favorites) => {
      //     // console.log(removeItem, favorites)
      //     setStore({ favorites: favorites.filter(item => item != removeItem)})
		},
	};
};

export default getState;